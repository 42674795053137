import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/forecastcomments", {params: removeEmptyParams(filter)}).then(res => {
                res.data.forEach(e => {
                    this.formatRes(e);
                })
                return res.data;
            }
        );
    },
    findById(id) {
        return Api().get("/forecastcomment/" + id).then(res => this.formatRes(res.data));
    },
    create(forecastComment) {
        return Api().post("/forecastcomment", forecastComment).then(res => this.formatRes(res.data));
    },
    update(forecastComment) {
        return Api().put("/forecastcomment/" + forecastComment.id, forecastComment).then(res => this.formatRes(res.data));
    },
    delete(forecastComment) {
        return Api().delete("/forecastcomment/" + forecastComment.id).then(() => forecastComment);
    },
    formatRes(e) {
        if(e.dateCreated) e.dateCreated = new Date(e.dateCreated);
        return e;
    }
}